<template>
  <div>
    <el-tabs v-model="activeName" @tab-click="handleClick">
      <el-tab-pane label="总体" name="1"></el-tab-pane>
      <el-tab-pane label="按区域" name="2"></el-tab-pane>
      <el-tab-pane label="按学校" name="3"></el-tab-pane>
      <el-tab-pane label="按班级" name="4"></el-tab-pane>
    </el-tabs>
    <div class="title">{{ examName }}</div>
    <el-select
      v-if="isAreaSelect"
      v-model="areaValue"
      @change="changeAreaSection"
      filterable
      placeholder="选择区域"
    >
      <el-option
        v-for="item in areaOptions"
        :key="item.sysOrgAreaId"
        :label="item.sysOrgAreaName"
        :value="item.sysOrgAreaId"
      ></el-option>
    </el-select>
    <el-select
      v-if="isSchoolSelect"
      v-model="schoolValue"
      @change="changeSchoolSection"
      filterable
      placeholder="选择学校"
    >
      <el-option
        v-for="item in schoolOptions"
        :key="item.sysOrgSchoolId"
        :label="item.sysOrgSchoolName"
        :value="item.sysOrgSchoolId"
      ></el-option>
    </el-select>
    <el-select
      v-if="isClassSelect"
      v-model="classValue"
      @change="changeSchoolClassSection"
      filterable
      placeholder="选择班级"
    >
      <el-option
        v-for="item in classOptions"
        :key="item.sysOrgSchoolClassId"
        :label="item.sysOrgSchoolClassName"
        :value="item.sysOrgSchoolClassId"
      ></el-option>
    </el-select>
    <!-- 图表部分 -->
    <div
      ref="scoreChart"
      id="scoreChart"
      style="width: 100%; height: 350px"
    ></div>
    <div class="picName">总体成绩分布图</div>
    <div class="setContent">
      <span style="display: inline-block">设置节点</span>
      <div style="display: inline-block">
        <span class="scoreNode" v-for="(v, i) in this.nodeList" :key="i">
          {{ v }}
          <el-button
            v-throttle
            class="scoreNodeBtn"
            type="danger"
            icon="el-icon-close"
            v-show="canDelete"
            circle
            @click="deleteNode(i)"
          ></el-button>
        </span>
        <el-input
          class="setNodeInput"
          type="text"
          style="width: 90px"
          v-model="setNodeNum"
          placeholder="输入分数"
          maxlength="5"
          @blur="intSupply(setNodeNum)"
          @input="setNodeNum = setNodeNum.replace(/[^\d.]/g, '')"
        />
        <el-button
          v-throttle
          class="setNodeBtn"
          type="primary"
          icon="el-icon-plus"
          @click="setNodeScore"
        ></el-button>
      </div>
    </div>
    <div class="setContent">
      <span>成绩区间</span>
      <div class="scale">
        <span
          class="segment"
          v-for="(v, i) in this.nodeList"
          :key="i"
          :style="'left:' + (v / fullScore) * 100 + '%'"
        >
          <span class="segmentScore">{{ v }}</span>
        </span>
        <span class="zeroScore">0分</span>
        <span class="fullScore">{{ fullScore }}分</span>
      </div>
    </div>
    <div class="btnBlock">
      <el-button v-throttle type="primary" @click="updateNode">运行</el-button>
      <el-button v-throttle type="primary" @click="reGetNode">重置</el-button>
    </div>
  </div>
</template>

<script>
import {
  getScoreSpread,
  getThreshold,
  getScoreCensus,
} from '@/api/score/scoreCensus.js'
export default {
  data() {
    return {
      //图表
      scoreChart: '',
      //总人数
      totalCount: 0,
      chartDataList: [],
      //控制选择框
      isAreaSelect: false,
      isSchoolSelect: false,
      isClassSelect: false,
      //头部标签页
      activeName: '1',
      //列表区域栏
      isShow: false,
      //下拉框选值
      areaValue: '',
      schoolValue: '',
      classValue: '',
      //下拉框数组
      areaOptions: [],
      schoolOptions: [],
      classOptions: [],
      //路由拿到的数据
      examId: '',
      subjectType: '',
      //设置节点输入框
      setNodeNum: '',
      //节点
      nodeList: [],
      //满分节点
      fullScore: 0,
      canDelete: true,
      scoreSpreadParam: {
        subjectType: '',
        nodeScore: [],
        orgSysAreaId: '',
        orgSysOrgSchoolId: '',
        orgSysOrgSchoolClassId: '',
      },
      examName: '',
    }
  },

  mounted() {
    //获取路由参数
    this.examId = Number(this.$route.query.examId)
    this.subjectType = this.$route.query.subject
    this.scoreSpreadParam.subjectType = this.subjectType
    this.getScoreNode()
  },

  watch: {
    nodeList(newValue) {
      //当节点只剩一个时不显示删除按钮
      if (newValue.length == 1) {
        this.canDelete = false
      } else {
        this.canDelete = true
      }
      if (newValue.indexOf(this.fullScore) > 0) {
        this.nodeList.splice(newValue.indexOf(this.fullScore), 1)
      }
    },
  },

  methods: {
    handleSizeChange(val) {
      console.log(`每页 ${val} 条`)
    },
    handleCurrentChange(val) {
      console.log(`当前页: ${val}`)
    },

    //切换选项卡
    handleClick(tab, event) {
      this.tabName = tab
      if (tab.name == 1) {
        this.isShow = false
        this.isAreaSelect = false
        this.isSchoolSelect = false
        this.isClassSelect = false
        this.scoreSpreadParam.orgSysAreaId = ''
        this.scoreSpreadParam.orgSysOrgSchoolId = ''
        this.scoreSpreadParam.orgSysOrgSchoolClassId = ''
        this.updateNode()
      }
      if (tab.name == 2) {
        getScoreCensus(this.examId).then((res) => {
          let dataList = res
          this.isShow = true
          this.isAreaSelect = true
          this.isSchoolSelect = false
          this.isClassSelect = false
          //赋值给选择下拉框并且默认选中第一个
          this.areaOptions = dataList.data.sysOrgAreaList
          if (this.areaOptions.length > 0) {
            this.areaValue = this.areaOptions[0].sysOrgAreaId
          }
          this.scoreSpreadParam.orgSysAreaId = this.areaValue
          this.scoreSpreadParam.orgSysOrgSchoolId = ''
          this.scoreSpreadParam.orgSysOrgSchoolClassId = ''
          this.updateNode()
        })
      }
      if (tab.name == 3) {
        getScoreCensus(this.examId).then((res) => {
          let dataList = res
          this.isShow = true
          this.isAreaSelect = false
          this.isSchoolSelect = true
          this.isClassSelect = false
          //赋值给选择下拉框并且默认选中第一个
          this.schoolOptions = dataList.data.sysOrgSchoolList
          if (this.schoolOptions.length > 0) {
            this.schoolValue = this.schoolOptions[0].sysOrgSchoolId
          }
          this.scoreSpreadParam.orgSysAreaId = ''
          this.scoreSpreadParam.orgSysOrgSchoolId = this.schoolValue
          this.scoreSpreadParam.orgSysOrgSchoolClassId = ''
          this.updateNode()
        })
      }
      if (tab.name == 4) {
        getScoreCensus(this.examId).then((res) => {
          let dataList = res
          this.isShow = true
          this.isAreaSelect = false
          this.isSchoolSelect = true
          this.isClassSelect = true
          //赋值给选择下拉框并且默认选中第一个
          this.schoolOptions = dataList.data.sysOrgSchoolList
          if (this.schoolOptions.length > 0) {
            this.schoolValue = this.schoolOptions[0].sysOrgSchoolId
            this.classOptions =
              dataList.data.sysOrgSchoolList[0].sysOrgSchoolClassList
            if (
              dataList.data.sysOrgSchoolList[0].sysOrgSchoolClassList.length > 0
            ) {
              this.classValue =
                dataList.data.sysOrgSchoolList[0].sysOrgSchoolClassList[0].sysOrgSchoolClassId
            }
          }
          this.scoreSpreadParam.orgSysAreaId = ''
          this.scoreSpreadParam.orgSysOrgSchoolId = this.schoolValue
          this.scoreSpreadParam.orgSysOrgSchoolClassId = this.classValue
          this.updateNode()
        })
      }
    },

    //重置
    reGetNode() {
      this.getScoreNode()
      this.$message.success('重置成功')
    },

    //获取节点
    getScoreNode() {
      getThreshold(this.examId, this.subjectType).then((res) => {
        console.log(res, 'resa')
        var nodeData = res.data
        //记录满分节点
        this.fullScore = this.intFilter(nodeData.fullScore)
        console.log('nodeData ', nodeData)
        if (res.success) {
          ;(this.scoreSpreadParam.subjectType = this.subjectType),
            (this.scoreSpreadParam.nodeScore = [
              nodeData.fullScore,
              nodeData.excellentScoreThreshold,
              nodeData.highScoreThreshold,
              nodeData.passScore,
            ])
          getScoreSpread(this.examId, this.scoreSpreadParam).then((res) => {
            let { data } = res
            if (res.success) {
              this.examName = data.examName
              this.totalCount = data.totalCount
              //处理分数自动补齐一位小数点
              console.log(data.nodeInfoList, 'nodeInit')
              this.intListFilter(data.nodeInfoList)
              // handleNodeList
              this.chartDataList = []
              console.log(data.nodeInfoList, 'data.nodeInfoList')
              for (let i = 0; i < data.nodeInfoList.length; i++) {
                if (i == 0) {
                  this.chartDataList.push({
                    value: data.nodeInfoList[i].scopeCount,
                    name: `0≤分数<${data.nodeInfoList[i].nodeScore}`,
                  })
                } else if (i == data.nodeInfoList.length - 1) {
                  //最后一组数据小于等于满分
                  this.chartDataList.push({
                    value: data.nodeInfoList[i].scopeCount,
                    name: `${data.nodeInfoList[i - 1].nodeScore}≤分数≤${
                      data.nodeInfoList[i].nodeScore
                    }`,
                  })
                } else {
                  this.chartDataList.push({
                    value: data.nodeInfoList[i].scopeCount,
                    name: `${data.nodeInfoList[i - 1].nodeScore}≤分数<${
                      data.nodeInfoList[i].nodeScore
                    }`,
                  })
                }
              }
              //加载图标
              this.initChart()
              //更新设置节点
              let pScore = this.intFilter(nodeData.passScore)
              let eScore = this.intFilter(nodeData.excellentScoreThreshold)
              let hScore = this.intFilter(nodeData.highScoreThreshold)
              this.nodeList = [pScore, eScore, hScore]
              console.log(this.nodeList)
            }
          })
        }
      })
    },

    //处理整数加上零结尾
    intFilter(val) {
      let value = val.toString()
      if (!value.includes('.')) {
        return `${val}.0`
      } else {
        return val
      }
    },
    //处理大对象内整数加上零结尾
    intListFilter(obj) {
      for (let i = 0; i < obj.length; i++) {
        if (!obj[i].nodeScore.toString().includes('.')) {
          obj[i].nodeScore = `${obj[i].nodeScore}.0`
        } else {
          return obj[i].nodeScore
        }
      }
    },

    //输入框自动补全零
    intSupply(val) {
      if (val) {
        console.log(val)
        if (!val.toString().includes('.')) {
          this.setNodeNum = `${val}.0`
        }
      }
    },

    //初始化图表
    initChart() {
      var echarts = require('echarts')
      var chartDom = document.getElementById('scoreChart')
      var myChart = echarts.init(chartDom)
      var option
      option = {
        tooltip: {
          trigger: 'item',
        },
        legend: {
          top: '40%',
          left: '60%',
          orient: 'vertical',
        },
        series: [
          {
            name: '成绩分布图',
            type: 'pie',
            radius: ['50%', '80%'],
            avoidLabelOverlap: false,
            label: {
              show: false,
              position: 'center',
            },
            emphasis: {
              label: {
                show: false,
                fontSize: '40',
                fontWeight: 'bold',
              },
            },
            labelLine: {
              show: false,
            },
            data: this.chartDataList,
          },
        ],
        graphic: [
          {
            //环形图中间添加文字
            type: 'text', //通过不同top值可以设置上下显示
            right: '49%',
            top: '47%',
            style: {
              text: `${this.totalCount}`,
              textAlign: 'right',
              fill: '#101010', //文字的颜色
              width: 35,
              height: 35,
              fontSize: 38,
              fontFamily: 'Microsoft YaHei',
            },
          },
          {
            //环形图中间添加文字
            type: 'text', //通过不同top值可以设置上下显示
            left: 'center',
            top: '36%',
            style: {
              text: '总人数',
              textAlign: 'center',
              fill: '#606266', //文字的颜色
              width: 10,
              height: 10,
              fontSize: 16,
              fontFamily: 'Microsoft YaHei',
            },
          },
          {
            //环形图中间添加文字
            type: 'text', //通过不同top值可以设置上下显示
            left: '52%',
            top: '49%',
            style: {
              text: '人',
              textAlign: 'center',
              fill: '#606266', //文字的颜色
              width: 10,
              height: 10,
              fontSize: 20,
              fontFamily: 'Microsoft YaHei',
            },
          },
        ],
      }
      myChart.setOption(option)
    },

    //设置节点
    setNodeScore() {
      if (!this.setNodeNum) {
        this.$message({
          message: '请先填写分数',
          type: 'warning',
        })
        return
      }
      if (Number(this.setNodeNum) >= Number(this.fullScore)) {
        this.$message({
          message: '节点分数不能大于等于满分分数',
          type: 'warning',
        })
        this.setNodeNum = ''
        return
      }
      if (this.nodeList.length >= 5) {
        this.$message({
          message: '最多设置5个节点',
          type: 'warning',
        })
        this.setNodeNum = ''
        return
      }

      if (this.nodeList.indexOf(this.setNodeNum) > -1) {
        this.$message({
          message: '不能重复添加节点',
          type: 'error',
        })
        this.setNodeNum = ''
        return
      }
      this.nodeList.push(this.setNodeNum)
      console.log(this.nodeList)
      this.setNodeNum = ''
    },

    //点击运行更新节点
    updateNode() {
      let nodeList = this.nodeList
      nodeList.push(this.fullScore)
      this.scoreSpreadParam.subjectType = this.subjectType
      this.scoreSpreadParam.nodeScore = nodeList
      for (let key in this.scoreSpreadParam) {
        if (
          this.scoreSpreadParam[key] === 0 ||
          this.scoreSpreadParam[key] === '' ||
          this.scoreSpreadParam[key] === undefined
        ) {
          delete this.scoreSpreadParam[key]
        }
      }
      getScoreSpread(this.examId, this.scoreSpreadParam).then((res) => {
        let { data } = res
        if (res.success) {
          this.totalCount = data.totalCount
          //处理分数自动补齐一位小数点
          this.intListFilter(data.nodeInfoList)
          // handleNodeList
          this.chartDataList = []
          console.log(data.nodeInfoList, 'data.nodeInfoList')
          for (let i = 0; i < data.nodeInfoList.length; i++) {
            if (i == 0) {
              this.chartDataList.push({
                value: data.nodeInfoList[i].scopeCount,
                name: `0≤分数<${data.nodeInfoList[i].nodeScore}`,
              })
            } else if (i == data.nodeInfoList.length - 1) {
              //最后一组数据小于等于满分
              this.chartDataList.push({
                value: data.nodeInfoList[i].scopeCount,
                name: `${data.nodeInfoList[i - 1].nodeScore}≤分数≤${
                  data.nodeInfoList[i].nodeScore
                }`,
              })
            } else {
              this.chartDataList.push({
                value: data.nodeInfoList[i].scopeCount,
                name: `${data.nodeInfoList[i - 1].nodeScore}≤分数<${
                  data.nodeInfoList[i].nodeScore
                }`,
              })
            }
          }
          //加载图标
          this.initChart()
          //更新设置节点
          // console.log(nodeData, '1111');
          // let pScore = this.intFilter(nodeData.passScore)
          // let eScore = this.intFilter(nodeData.excellentScoreThreshold)
          // let hScore = this.intFilter(nodeData.highScoreThreshold)
          // this.nodeList = [pScore, eScore, hScore]
          // console.log(this.nodeList);
          this.$message.success('运行成功')
        }
      })
    },
    //删除节点
    deleteNode(i) {
      this.nodeList.splice(i, 1)
    },
    //选择区域
    changeAreaSection() {
      this.scoreSpreadParam.orgSysAreaId = this.areaValue
      this.updateNode()
    },
    //选择学校
    changeSchoolSection() {
      this.scoreSpreadParam.orgSysOrgSchoolId = this.schoolValue
      this.classValue = ''
      this.schoolOptions.forEach((v) => {
        if (v.sysOrgSchoolId == this.schoolValue) {
          this.classOptions = v.sysOrgSchoolClassList
        }
      })
      this.updateNode()
    },
    //选择班级
    changeSchoolClassSection() {
      this.scoreSpreadParam.orgSysOrgSchoolId = this.schoolValue
      this.scoreSpreadParam.orgSysOrgSchoolClassId = this.classValue
      this.updateNode()
    },
  },
}
</script>

<style lang="scss" scoped>
.title {
  font-size: 20px;
  margin-top: 20px;
  margin-bottom: 20px;
}

.picName {
  text-align: center;
  font-size: 21px;
  margin-top: 20px;
}

.scale {
  display: inline-block;
  width: 300px;
  height: 15px;
  border: 1px solid #797979;
  border-top: 0px solid #ff0000;
  margin-left: 20px;

  .zeroScore {
    position: absolute;
    margin-top: 18px;
    margin-left: -8px;
  }
  .fullScore {
    position: absolute;
    margin-top: 18px;
    margin-left: 285px;
  }
}

.segment {
  position: relative;
  border-left: 1px solid red;
  left: 40%;
  .segmentScore {
    position: absolute;
    top: -20px;
    left: -12px;
  }
}

.setContent {
  margin-top: 50px;
  margin-left: 650px;
}

.scoreNode {
  position: relative;
  display: inline-block;
  width: 80px;
  height: 38px;
  border: 1px solid #dfe2e8;
  border-radius: 5px;
  text-align: center;
  line-height: 38px;
  margin-left: 8px;
  .scoreNodeBtn {
    position: absolute;
    transform: scale(0.25, 0.25);
    font-size: 40px;
    left: 45px;
    bottom: 5px;
  }
}

.setNodeInput {
  margin-left: 20px;
}

.setNodeBtn {
  margin-left: 8px;
}

.btnBlock {
  text-align: center;
  margin-top: 40px;
}
</style>
